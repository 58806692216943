import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';

import SEO from '../components/seo';
import Generator from '../components/generator';
import useSubscriptionContext from '../hooks/subscription-context';
import useGlobalContext from '../hooks/global-context';
import useFormHelper from '../hooks/form';
import { routeConfigMap } from '../constants/navigation';
import { PageRoute } from '../types/routes';
import { GlobalActionType } from '../types/global-context';
import { GeneratorMode } from '../types/generator';
import { generatorBuilderFormSchema } from '../utils/form-schema';
import useMediaQuery from '../hooks/media-query';
import MobileLanding from '../components/mobile-landing';

/**
 * Mirosign homepage renders a Generator, which has varying levels of actions
 * available to users depending on their auth/subscription states. This means
 * guest users can still use the main Generator features and see their signature
 * design change, but they will not be able to download the full HTML result
 * unless they purchase a subscription.
 */
const IndexPage = ({ location }: PageProps) => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  const { isAuthenticated } = useAuth0();
  const [{ isAppLoading }, dispatch] = useGlobalContext();
  const { currentPlan, isActive } = useSubscriptionContext();
  const { isSavedUserDataStale, reinitialiseUserForm } = useFormHelper();

  const { title } = routeConfigMap[location?.pathname as PageRoute];

  /**
   * Sets the Generator mode and resets the form using existing values from
   * authenticated user data.
   *
   * This should run only for authenticated users and only for the first
   * render of the index page - After form data has become dirty or been saved,
   * the original saved data becomes stale and will not be updated unless the
   * whole app is reloaded (when auth happens).
   */
  useEffect(() => {
    if (!isAuthenticated || isSavedUserDataStale) return;

    const isBuilder = !!(isActive && currentPlan === 'TEAM');

    if (isBuilder) {
      dispatch({
        type: GlobalActionType.SetFormValidationSchema,
        formValidationSchema: generatorBuilderFormSchema,
      });
    }

    reinitialiseUserForm();

    dispatch({
      type: GlobalActionType.SetGeneratorMode,
      mode: isBuilder ? GeneratorMode.TeamBuilder : GeneratorMode.Personal,
    });
  }, [
    currentPlan,
    dispatch,
    isActive,
    isAuthenticated,
    reinitialiseUserForm,
    isAppLoading,
    isSavedUserDataStale,
  ]);

  return (
    <>
      <SEO title={title} />
      {isMobile ? <MobileLanding /> : <Generator />}
    </>
  );
};

export default IndexPage;
